import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

// import "./firebase.js";
import "./style.css";

import Title from "./images/title.png";
import Sea from "./images/sea-smaller.png";
import Ship from "./images/ship.gif";
import Sky from "./images/sky-dawn.png";
import { truncateAddress } from "./helper";

// if (process.env.NODE_ENV !== "production") {
//   console.log("Looks like we are in development mode!");
// }

async function getComponent() {
  const element = document.querySelector("[data-world]");
  const { default: _ } = await import("lodash");

  const endPhrases = [
    "Avast, me hearties! The quest be complete, the tale be told.",
    "Sun sets on this voyage, raise a mug, me shipmates.",
    "Final parley, stow the treasure, adieu, me crew!",
    "Curtain falls on this seafaring escapade, me mateys.",
  ];

  const randomIndex = Math.floor(Math.random() * endPhrases.length);
  const selectedPhrase = endPhrases[randomIndex];
  const endTitle = document.querySelector("[data-end-title]");
  endTitle.innerText = selectedPhrase;

  const innerHTML = `
  <div class="score" alt="score" data-score>0</div>  
  <p class="welcome" data-welcome-text>${welcomeTextFunction()}</p>
  <img class="start-screen" alt="start" data-start-screen src="${Title}">
  <img class="sea" alt="sea" data-sea src="${Sea}">
  <img class="sea" alt="sea" data-sea src="${Sea}">
  <img class="ship" alt="ship" data-ship src="${Ship}">
  <img class="sky" alt="sky" data-sky src="${Sky}">
`;

  element.innerHTML = innerHTML;
  import("./firebase.js");
  import("./script.js");
  return element;
}

function welcomeTextFunction() {
  let message = window.location.search.substring(1);
  if (message === "player") {
    return "Connect wallet to earn rewards";
  } else {
    return `Welcome back Seilor! <br/><span>${truncateAddress(message)}</span>`;
  }
}

getComponent().then((component) => {
  document.body.appendChild(component);
});
